.blink {
  -webkit-animation: blink 2s infinite both;
          animation: blink 2s infinite both;
}

@-webkit-keyframes blink {
0%,
50%,
100% {
  opacity: 1;
}
25%,
75% {
  opacity: 0;
}
}
@keyframes blink {
0%,
50%,
100% {
  opacity: 1;
}
25%,
75% {
  opacity: 0;
}
}