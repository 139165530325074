#banner {
    animation: banneranimation 15s infinite;
    animation-timing-function: linear;
    position: relative;
}

@keyframes banneranimation {
    0% {
        left: 1200px;
    }

    100% {
        left: -500px;
    }
}

#slotgame {
    animation: slide 60s infinite;
    animation-timing-function: linear;
    position: relative;
}

#slotgame:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@keyframes slide {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-1500px, 0, 0);
        /* The image width */
    }
}